// import lib(s)
import React, { useEffect, useState, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import styles from "./UploadTask.module.css"
import { useTheme } from "@emotion/react"

import { useAxiosPrivate, useDocumentTitle } from "../../hooks"
import { PAGE_TITLE } from "../../constants/constants"
import { METHODS, errorHandler } from "../../hooks/useFetch"


import Layout from "../../layouts/common/Layout"
import PlaceholderText from "../../components/common/SampleText"
import Loader from "../../components/common/Loader"
import toast from "react-hot-toast"

import Card from "@mui/material/Card"
import Divider from "@mui/material/Divider"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Box from "@mui/material/Box"
import CardContent from "@mui/material/CardContent"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import Autocomplete from "@mui/material/Autocomplete"
import TextField from "@mui/material/TextField"
import FormLabel from "@mui/material/FormLabel"
import FormControl from "@mui/material/FormControl"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormHelperText from "@mui/material/FormHelperText"
import Checkbox from "@mui/material/Checkbox"
import Switch from "@mui/material/Switch"
import Slider from "@mui/material/Slider"
import Link from "@mui/material/Link"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import ListSubheader from '@mui/material/ListSubheader';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Chip from "@mui/material/Chip"
import { Grid } from "@mui/material"

import { MuiColorInput } from 'mui-color-input'

import AddIcon from '@mui/icons-material/Add';
import BackspaceIcon from '@mui/icons-material/Backspace';

import { useDropzone } from "react-dropzone"

import atc2json from "../../utils/atc2json"
import universalFormatter from "../../utils/universalFormatter"

import copy2cliboard from "../../utils/copy2cliboard"

const UploadTask = () => {
    
	useDocumentTitle(PAGE_TITLE.UPLOAD_TASK)
	const dispatch = useDispatch()
	const CurrentUserEmail = useSelector((state) => state.auth.user.email)
	const theme = useTheme()
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(null)
	const [Files, setFiles] = useState({})
	const [GlobalAnn, setGlobalAnn] = useState([])
	const [NewGlobalAnn, setNewGlobalAnn] = useState("")
	const [Beats, setBeats] = useState([])
	const [NewBeat, setNewBeat] = useState({name: "", color: "", slug: ""})
	const [Intervals, setIntervals] = useState([])
	const [NewInterval, setNewInterval] = useState({name: "", color: "", slug: ""})
	const [TaskName, setTaskName] = useState("")
	const [TaskType, setTaskType] = useState(1)
	const [disabled, setDisabled] = useState(false)

	const addGlobalAnn = (e) => {
		if (NewGlobalAnn === "") {
			toast.error("Global Annotation cannot be empty")
			return
		}
		const annArray = NewGlobalAnn.replace(" ","").split(",")
		// remove empty string from array
		const filtered = annArray.filter(function (el) {
			return el != "";
		});

		const newGlobalAnn = [...GlobalAnn, ...filtered]
		setGlobalAnn(newGlobalAnn)
		toast.success("Added Global Annotation")
		setNewGlobalAnn("")
	}


	const addBeat = (e) => {
		if (NewBeat.name === "") {
			toast.error("Beat name cannot be empty")
			return
		}
		if (NewBeat.color === "") {
			toast.error("Beat color cannot be empty")
			return
		}

		const newBeats = [...Beats, {...NewBeat, slug: NewBeat.name.toLowerCase()[0]}]
		setBeats(newBeats)
		toast.success("Added Beat")
		setNewBeat({name: "", color: "", slug: ""})
	}

	const addInterval = (e) => {
		if (NewInterval.name === "") {
			toast.error("Interval name cannot be empty")
			return
		}
		if (NewInterval.color === "") {
			toast.error("Interval color cannot be empty")
			return
		}

		const newIntervals = [...Intervals, {...NewInterval, slug: NewInterval.name.toLowerCase()[0]}]
		setIntervals(newIntervals)
		toast.success("Added Interval")
		setNewInterval({name: "", color: "", slug: ""})
	}

	const axiosPrivate = useAxiosPrivate()

    useEffect(() => {
        window.sidebar.open()
    }, [])

	const onClickUpload = async () => {
		if (TaskName === "") {
			toast.error("Task Name cannot be empty")
			return
		}
		if (Object.keys(Files).length === 0) {
			toast.error("Please select at least one recording")
			return
		}
		setLoading(true)
		let annotation = {global: [], labels: {beats: [], intervals: []}}
		// Global Annotations 
		GlobalAnn.map(g => {
			annotation.global.push({[g]: false})
		})

		// Beat Annotations
		annotation.labels.beats = [{name: "Normal", color: "#6CCA6E", slug:"n"},...Beats]

		// Interval Annotations
		annotation.labels.intervals = [{name: "Noise", color: "#8c8c8c", slug:"no"},...Intervals]

		let tasks = []
		Object.values(Files).map((file) => {
			let uniEcg = JSON.parse(file)
			if (TaskType == 2 && uniEcg.hasOwnProperty("kai12Analysis")) {
				uniEcg.annotations = {
					...annotation,
					overreadMode: true,
					originalDx: uniEcg.kai12Analysis,
					confirmedDx: null,
				}
			} else {
				uniEcg.annotations = annotation
			}
			tasks.push(JSON.stringify(uniEcg))
		})
		try {
			const { data: postData } = await axiosPrivate[METHODS.POST.toLowerCase()](`task`, {
				"name": TaskName,
				"taskType": "ekg",
				"recordings": tasks
			})
			toast.success("Task created successfully")
			setTaskName("")
			setFiles({})
		} catch (error) {
			if (typeof error === "string") {
				toast.error(error)
			} else {
				toast.error("Error fetching Task Recordings")
			}
			setError(errorHandler(error))
		} finally {
			setLoading(false)
		}
	}

	const onDrop = useCallback((acceptedFiles) => {
		setLoading(true)
		let success = 0
		let failed = 0
		if (acceptedFiles.length > 0) {
			try {
				acceptedFiles.map((file) => {
					const reader = new FileReader()
					reader.onload = () => {
						const fileAsBinaryString = reader.result
						switch (file.name.split(".").pop()) {
							case "atc":
								const readingFile = new Promise((resolve, reject) => {
									const reader = new FileReader()
									reader.onloadend = (e) => resolve(e.target.result)
									reader.onerror = (e) => reject(e.target.error)
									reader.readAsArrayBuffer(file)
								}).then((FileBuffer) => {
									const data = new Uint8Array(FileBuffer)
									const { parsed_data, error_msg } = atc2json.parse(data)
									let uniEcg = JSON.parse(JSON.stringify(parsed_data))
									uniEcg.type = "atc"
									uniEcg.filename = file.name
									uniEcg.annotator = [{time: new Date().toISOString(),person: CurrentUserEmail ,message: "File Uploaded"}]
									// uniEcg.annotations = { beatLabels: [], intervals: [], global: [] }
									setFiles((prev) => ({ ...prev, [file.name]: JSON.stringify(uniEcg) }))
									success++
									// toast.success(`${file.name.length > 10 ? file.name.substring(0, 20) + "(...)." + file.name.split(".").pop() : file.name} loaded successfully`)
									if (error_msg) {
										toast.error(error_msg)
										setLoading(false)
										failed++
									}
								})
								break
							case "json":
								try {
									let uniEcg = JSON.parse(fileAsBinaryString)
									// if (!uniEcg.hasOwnProperty("rawEcg")) {
									// 	throw new Error("Invalid ECG JSON file")
									// }
									uniEcg.type = "json"
									uniEcg.filename = file.name
									uniEcg.annotator = [{time: new Date().toISOString(),person: CurrentUserEmail ,message: "File Uploaded"}]
									// uniEcg.annotations = { beatLabels: [], intervals: [], global: [] }
									setFiles((prev) => ({ ...prev, [file.name]: JSON.stringify(uniEcg) }))
									success++
									// toast.success(`${file.name.length > 10 ? file.name.substring(0, 20) + "(...)." + file.name.split(".").pop() : file.name} loaded successfully`)
								} catch (error) {
									failed++
									toast.error(`${file.name.length > 10 ? file.name.substring(0, 20) + "(...)." + file.name.split(".").pop() : file.name} is not a valid ECG JSON file`)
								}
								break
							default:
								failed++
								toast.error("Invalid file type")
								setLoading(false)
						}
					}
					reader.onabort = () => console.log("file reading was aborted")
					reader.onerror = () => console.log("file reading has failed")
					reader.readAsBinaryString(file)
				})
			} catch (error) {
				if (typeof error === "string") {
					toast.error(error)
				} else {
					toast.error("Error fetching Task Recordings")
				}
				console.log(error)
				setError(error)
			} finally {
				setLoading(false)
			}
			setTimeout(() => {
				if ( acceptedFiles.length === success + failed) {
					if(acceptedFiles.length === success) {
						toast.success(`${success} files loaded successfully`)
					} else {
						toast(`Of the ${acceptedFiles.length} files, ${success} loaded successfully and ${failed} failed`)
					}
				}
			},  acceptedFiles.length*100)
		} else {
			setLoading(false)
			toast.error("No ECG file selected")
		}
	}, [])

	const { getRootProps, getInputProps, isDragActive, isDragReject } =
		useDropzone({
			accept: {
				"application/atc": [".atc"],
				"application/json": [".json"],
			},
			disabled,
			onDrop,
		})

	return (
		<Layout.CenterWrapper>
			{loading || error?.status === 421 ? (
				<Loader />
			) : error ? (
				<PlaceholderText>Error</PlaceholderText>
			) : (
				<div className={styles.root}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography variant="h5" component="div">
									Step 1: <b>Task Details</b>
								</Typography>
							</Grid>
							<Grid item xs={9}>
								<Card sx={{ padding: theme.spacing(3)}}>
									<TextField
										id="task_name"
										label="Task Name"
										variant="outlined"
										value={TaskName}
										onChange={(e) => {
											setTaskName(e.target.value)
										}}
										sx={{ whiteSpace: "nowrap"}}
										fullWidth
										size="large"
									/>
								</Card>
							</Grid>
							<Grid item xs={3}>
								<Card sx={{ padding: theme.spacing(3) }}>
									<FormControl fullWidth sx={{ whiteSpace: "nowrap"}} size="large">
										<InputLabel id="task-type-label">Task Type</InputLabel>
										<Select
											labelId="task-type-label"
											id="task-type"
											label="Task Type"
											value={TaskType}
											onChange={(e) => setTaskType(e.target.value)}
											name="adaptive"
										>
											<MenuItem value={1}>ECG</MenuItem>
											<MenuItem value={2}>12L ECG Overread</MenuItem>
										</Select>
									</FormControl>
								</Card>
							</Grid>
							<Grid item xs={12} mt={2}>
								<Typography variant="h5" component="div">
									Step 2: <b>Recordings</b>
								</Typography>
								<Typography variant="p" component="div">
									This tool currently supports ATC and 12L JSON Files only.
								</Typography>
							</Grid>
							<Grid item xs={8}>
								<Card sx={{ padding: theme.spacing(1), height:"50vh"}}>
									<div className={styles.uploadContainer} {...getRootProps()}>
										<input className={styles.inputBox} {...getInputProps()} />
										{!isDragActive && !isDragReject && (
											<h3 style={{ fontWeight: "500" }}>
												Click or Drag & drop ECG files here
											</h3>
										)}
										{isDragActive && !isDragReject && (
											<h3 style={{ fontWeight: "500" }}>Drop the file to parse...</h3>
										)}
										{isDragReject && (
											<h3 style={{ fontWeight: "500" }}>Drop the file to parse...</h3>
										)}
									</div>
								</Card>
							</Grid>
							<Grid item xs={4}>
								<Card sx={{ padding: theme.spacing(3), height:"50vh", overflowY: "scroll"}}>
									<Typography variant="h5" mb={2} component="div">
										<b>Recordings:</b>
									</Typography>
									<div style={{width:"100%",}}>
										{Object.keys(Files) && Object.keys(Files).map((file, index) => (
											<Chip
												key={index}
												label={file}
												color="primary"
												onDelete={() => {
													const newFiles = { ...Files }
													delete newFiles[file]
													setFiles(newFiles)
												}}
												style={{ margin: "3px" }}
											/>

										))}
									</div>

									<Button
										variant="contained"
										sx={{width: "100%", marginTop:"10px"}}
										size="large"
										color="error"
										onClick={() => {
											toast.success("All files cleared")
											setFiles({})
										}}
									>
										Clear All files
									</Button>
								</Card>
							</Grid>

							<Grid item xs={12} mt={2}>
								<Typography variant="h5" component="div">
									Step 3: <b>Annotation Options</b>
								</Typography>
							</Grid>

							{TaskType !== 2 && <Grid item xs={4} mt={2}>
								<Card sx={{ padding: theme.spacing(3) }}>
									<Typography variant="h6" component="div">
										<b>Global Annotations:</b>
									</Typography>

									<div style={{width:"100%", height: "15vh", overflow: "scroll", padding: "10px 0px"}}>
										{GlobalAnn && GlobalAnn.map((Ga, index) => (
											<Chip
												key={index}
												label={Ga}
												color="primary"
												onDelete={() => {
													const newGlobalAnn = GlobalAnn.filter((item) => item !== Ga)
													setGlobalAnn(newGlobalAnn)
												}}
												style={{ margin: "3px" }}
											/>
										))}
									</div>
									<Grid spacing={1} container>
										<Grid item xs={7.5}>
											<TextField
												label="Global Annotation"
												variant="outlined"
												value={NewGlobalAnn}
												onChange={(e) => {
													setNewGlobalAnn(e.target.value)
												}}
												fullWidth
												size="small"
												onKeyDown={(e) => {
													if (e.key === "Enter") {
														addGlobalAnn()
												}}}
											/>
										</Grid>
										<Grid item xs={2} mr={1}>
											<Button
												variant="contained"
												color="error"
												onClick={() => setGlobalAnn([])}
											>
												<BackspaceIcon />
											</Button>
										</Grid>
										<Grid item xs={2}>
											<Button
												variant="contained"
												onClick={addGlobalAnn}
											>
												<AddIcon />
											</Button>
										</Grid>
									</Grid>
									
								</Card>
							</Grid>}

							<Grid item xs={4} mt={2}>
								<Card sx={{ padding: theme.spacing(3) }}>
									<Typography variant="h6" component="div">
										<b>Beat Types:</b>
									</Typography>

									<div style={{width:"100%", height: "15vh", overflow: "scroll", padding: "10px 0px"}}>
										<Chip
											key={"exisitng"}
											label={"Normal"}
											color="primary"
											style={{ margin: "3px", backgroundColor: "#6CCA6E" }}
										/>
										{Beats && Beats.map((b, index) => (
											<Chip
												key={index}
												label={b.name}
												color="primary"
												onDelete={() => {
													const newBeats = Beats.filter((item) => item !== b)
													setBeats(newBeats)
												}}
												style={{ margin: "3px", backgroundColor: b.color }}
											/>
										))}
									</div>
									<Grid spacing={1} container>
										<Grid item xs={4}>
											<TextField
												label="Name"
												variant="outlined"
												value={NewBeat.name}
												onChange={(e) => {
													setNewBeat({...NewBeat, name: e.target.value})
												}}
												size="small"
												onKeyDown={(e) => {
													if (e.key === "Enter") {
														addBeat()
												}}}
											/>
										</Grid>
										<Grid item xs={5.5}>
											<MuiColorInput
												label="Color"
												variant="outlined"
												value={NewBeat.color}
												onChange={color => { setNewBeat({...NewBeat, color: color}) }}
												size="small"
												onKeyDown={(e) => {
													if (e.key === "Enter") {
														addBeat()
												}}}
											/>
										</Grid>
										<Grid item xs={2}>
											<Button
												variant="contained"
												fullWidth
												onClick={addBeat}
											>
												<AddIcon />
											</Button>
										</Grid>
									</Grid>
								</Card>
							</Grid>

							<Grid item xs={4} mt={2}>
								<Card sx={{ padding: theme.spacing(3) }}>
									<Typography variant="h6" component="div">
										<b>Interval Types:</b>
									</Typography>

									<div style={{width:"100%", height: "15vh", overflow: "scroll", padding: "10px 0px"}}>
										<Chip
											key={"exisitng"}
											label={"Noise"}
											color="primary"
											style={{ margin: "3px", backgroundColor: "#8c8c8c" }}
										/>
										{Intervals && Intervals.map((b, index) => (
											<Chip
												key={index}
												label={b.name}
												color="primary"
												onDelete={() => {
													const newIntervals = Intervals.filter((item) => item !== b)
													setIntervals(newIntervals)
												}}
												style={{ margin: "3px", backgroundColor: b.color }}
											/>
										))}
									</div>
									<Grid spacing={1} container>
										<Grid item xs={4}>
											<TextField
												label="Name"
												variant="outlined"
												value={NewInterval.name}
												onChange={(e) => {
													setNewInterval({...NewInterval, name: e.target.value})
												}}
												size="small"
												onKeyDown={(e) => {
													if (e.key === "Enter") {
														addInterval()
												}}}
											/>
										</Grid>
										<Grid item xs={5.5}>
											<MuiColorInput
												label="Color"
												variant="outlined"
												value={NewInterval.color}
												onChange={color => { setNewInterval({...NewInterval, color: color}) }}
												size="small"
												onKeyDown={(e) => {
													if (e.key === "Enter") {
														addInterval()
												}}}
											/>
										</Grid>
										<Grid item xs={2}>
											<Button
												variant="contained"
												fullWidth
												onClick={addInterval}
											>
												<AddIcon />
											</Button>
										</Grid>
									</Grid>
								</Card>
							</Grid>
							

							<Grid item xs={12} mt={2}>
								<Typography variant="h5" component="div">
									Step 4: <b>Upload</b>
								</Typography>
							</Grid>
							<Grid item xs={4}>
								<Card sx={{ padding: theme.spacing(2) }}>
								<Typography variant="p" pb={2} component="div">
									Uploading <b>{TaskName || "Untitled"}</b> task with <b>{Object.keys(Files).length}</b> recordings.
								</Typography>
								<Button
									variant="contained"
									sx={{width: "100%"}}
									size="large"
									onClick={onClickUpload}
								>
									Upload
								</Button>
								</Card>
							</Grid>
							<Grid item xs={12} my={4} textAlign="center">
								<p>For bulk upload with <Link href="/bulk_upload_script.py">python script</Link> from local, <Link href="#" onClick={()=>{copy2cliboard(JSON.parse(window.localStorage.getItem("usr")).token); toast.success("Bearer Token Copied!")}}>Click here</Link> to copy bearer token.</p>
							</Grid>
						</Grid>
				</div>
			)}
		</Layout.CenterWrapper>
	)
}

export default UploadTask
